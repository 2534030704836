<template>
  <div class="col-md-12 card card-custom">
    <div class="card-body position-relative">
      <div class="text-right">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'projectDynamicModelsDynamicModel' })"
        >
          {{ $t("dynamicModels.cancelCreate") }}
        </button>
      </div>
      <div
        id="kt_wizard_dynamic_models"
        class="wizard wizard-1"
        data-wizard-state="step-content"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-file"></i>
                <h3 class="wizard-title">
                  {{ $t("dynamicModels.stepAnalyze") }}
                </h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-sliders"></i>
                <h3 class="wizard-title">
                  {{ $t("dynamicModels.stepBasicConfig") }}
                </h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-list"></i>
                <h3 class="wizard-title">
                  {{ $t("dynamicModels.stepTableConfig") }}
                </h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-rocket-launch"></i>
                <h3 class="wizard-title">{{ $t("dynamicModels.stepSave") }}</h3>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->
        <v-progress-linear v-if="isBusy" indeterminate />
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 mb-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-8">
            <div data-wizard-type="step-content">
              <StepAnalyze
                ref="step-analyze"
                :key="componentKey"
                :data="data"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepBasicConfiguration
                :key="componentKey"
                ref="step-basic-configuration"
                :analyzed-data="analyzedData"
                @analyze="reanalyzeFile"
              />
            </div>
            <div>
              <StepTableConfiguration
                :key="componentKey"
                ref="step-table-configuration"
                :analyzed-data="analyzedData"
                @primaryChanged="onPrimaryChanged"
              />
            </div>
            <div>
              <StepSave
                :key="componentKey"
                ref="step-save"
                :analyzed-data="analyzedData"
              />
            </div>
          </div>
        </div>
        <!--begin: Wizard Actions -->
        <div
          class="d-flex justify-content-between border-top pt-10 wizard-actions"
        >
          <div class="mr-2">
            <button
              class="btn btn-light-primary font-weight-bold text-uppercase px-sm-9 py-sm-4 px-3 py-2"
              data-wizard-type="action-prev"
              :disabled="isBusy"
            >
              {{ $t("dynamicModels.previous") }}
            </button>
          </div>
          <div>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-submit"
              :disabled="isBusy || !isValid"
              @click="storeModel"
            >
              {{ $t("dynamicModels.saveModel") }}
            </button>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-sm-9 py-sm-4 px-3 py-2"
              data-wizard-type="action-next"
              :disabled="data.fileType === null || isBusy || !primarySelected"
              @click.prevent="nextStep"
            >
              <span v-if="!isStepAnalyze">{{ $t("dynamicModels.next") }}</span>
              <span v-else>
                <i class="fal fa-magnifying-glass" />
                {{ $t("dynamicModels.analyze") }}
              </span>
            </button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </div>
    </div>
  </div>
</template>

<script>
import KTWizard from "@/assets/js/components/wizard";
import StepAnalyze from "@/components/Projects/DynamicModels/DynamicModel/Steps/StepAnalyze";
import StepBasicConfiguration from "@/components/Projects/DynamicModels/DynamicModel/Steps/StepBasicConfiguration";
import StepTableConfiguration from "@/components/Projects/DynamicModels/DynamicModel/Steps/StepTableConfiguration";
import StepSave from "@/components/Projects/DynamicModels/DynamicModel/Steps/StepSave";
import DynamicModels from "@/components/Projects/DynamicModels/DynamicModel/dynamicModels";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    StepAnalyze,
    StepBasicConfiguration,
    StepTableConfiguration,
    StepSave
  },
  data() {
    return {
      wizard: null,
      data: {
        mode: 0,
        file: null,
        text: "",
        fileType: null,
        config: {}
      },
      analyzedData: {},
      componentKey: 1,
      isBusy: false,
      primarySelected: true,
      isValid: true
    };
  },
  computed: {
    isStepAnalyze: function () {
      if (!this.wizard) return false;
      return this.wizard.getStep() === 1;
    },
    isStepBasicConfig: function () {
      if (!this.wizard) return false;
      return this.wizard.getStep() === 2;
    }
  },
  mounted() {
    this.initializeWizard();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.projectDynamicModelsDynamicModel"),
        route: "/project/dynamicModels/dynamicModel"
      },
      {
        title: this.$t("dynamicModels.createModel"),
        route: "/project/dynamicModels/dynamicModel/create"
      }
    ]);
  },
  methods: {
    initializeWizard() {
      this.wizard = new KTWizard("kt_wizard_dynamic_models", {
        clickableSteps: false
      });
    },
    nextStep() {
      this.primarySelected = true;
      if (this.isStepAnalyze) {
        this.wizard.stop();
        this.analyzeFile();
      } else if (this.isStepBasicConfig) {
        this.wizard.stop();
        this.data.config = this.$refs["step-basic-configuration"].getConfig();
        this.analyzedData.config = this.data.config;
        this.primarySelected = this.analyzedData.primary !== undefined;
        this.componentKey++;
        this.wizard.resume();
      }
    },
    analyzeFile() {
      this.isBusy = true;
      this.data.config = this.$refs["step-analyze"].getConfig();
      const data = this.getFormData();
      DynamicModels.analyze(data)
        .then(response => {
          if (response.status !== 200) {
            return this.$toast.fire({
              icon: "error",
              title: this.$t("error.error") + response.status
            });
          }
          this.analyzedData = response.data;
          this.componentKey++;
          this.wizard.goTo(2);
          this.isBusy = false;
          this.isValid = this.analyzedData.fields.length > 0;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    reanalyzeFile(config) {
      this.isBusy = true;
      this.data.config = config;
      const data = this.getFormData();
      DynamicModels.analyze(data)
        .then(response => {
          if (response.status !== 200) {
            return this.$toast.fire({
              icon: "error",
              title: this.$t("error.error") + response.status
            });
          }
          this.analyzedData.config = response.data.config;
          this.analyzedData.entries = response.data.entries;
          this.analyzedData.fields = response.data.fields;
          this.analyzedData.primary =
            response.data.primary ?? this.analyzedData.primary;
          this.componentKey++;
          this.isBusy = false;
          this.isValid = this.analyzedData.fields.length > 0;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    onPrimaryChanged() {
      this.primarySelected = true;
    },
    getFormData() {
      let formData = new FormData();
      if (this.data.mode === 0) {
        formData.append("file", this.data.file);
      } else if (this.data.mode === 1) {
        formData.append("string", this.data.text);
      }
      formData.append("type", this.data.fileType);
      Object.keys(this.data.config).forEach(key => {
        formData.append("config[" + key + "]", this.data.config[key]);
      });
      return formData;
    },
    storeModel() {
      this.isBusy = true;
      let me = this;
      let data = this.analyzedData;
      data.fields = this.mergeFields(data.fields, data.dynamic_fields);
      data.config.type = data.type;
      DynamicModels.store(data)
        .then(response => {
          this.isBusy = false;
          this.$swal.fire({
            icon: "success",
            title: this.$t("dynamicModels.modelStoredTitle"),
            text: this.$t("dynamicModels.modelStoredText", {
              name: response.data.data.classname
            }),
            confirmButtonText: this.$t("general.close"),
            allowOutsideClick: false,
            onClose() {
              me.$router.push({ name: "projectDynamicModelsDynamicModel" });
            }
          });
        })
        .catch(error => {
          this.isBusy = false;
          this.$swal.fire({
            icon: "error",
            title: this.$t("dynamicModels.modelStoreErrorTitle"),
            html: this.$t("dynamicModels.modelStoreErrorText", {
              msg: error.response.data.message
            }),
            showConfirmButton: false,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: this.$t("general.close")
          });
        });
    },
    mergeFields(fields = [], dynamic_fields = []) {
      if (!fields.length || !dynamic_fields.length) {
        return fields;
      }
      dynamic_fields.forEach(dynamicField => {
        delete dynamicField._showDetails;
        dynamicField.isDynamic = true;
        fields.push(dynamicField);
      });
      return fields;
    }
  }
};
</script>

<style lang="scss">
@import "../../../../assets/sass/pages/wizard/wizard-1";

.wizard-actions {
  position: sticky;
  background: #fff;
  padding-bottom: 20px;
  bottom: 0;
}
</style>
