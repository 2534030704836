<template>
  <div class="row">
    <div class="col-12" :class="{ 'd-none': !showTable }">
      <h5 class="font-weight-bold">{{ $t("dynamicModels.dynamicFields") }}:</h5>
    </div>
    <div class="col-12">
      <b-table
        ref="dynamicFieldsTable"
        :class="{ 'd-none': !showTable }"
        responsive
        :items="analyzedData.dynamic_fields"
        :fields="fields"
        bordered
        class="dataTable table table-head-custom"
        table-class="b-table-fixed"
        :show-empty="true"
        :empty-text="$t('table.noRecords')"
      >
        <!--begin: Checkbox for every field -->
        <template #cell()="data">
          <span class="switch switch-sm justify-content-center">
            <label>
              <input
                v-model="data.item[data.field.key]"
                type="checkbox"
                :disabled="
                  data.field.key === 'unsigned' ||
                  analyzedData.primary === data.item.name
                "
              />
              <span></span>
            </label>
          </span>
        </template>
        <!--end: Checkbox for every field-->
        <!--begin: Field "name" -->
        <template #cell(name)="data">
          <div
            class="cursor-pointer d-flex align-items-center"
            @click="data.toggleDetails"
          >
            <i
              class="fal mr-2"
              :class="
                data.item._showDetails ? 'fa-chevron-down' : 'fa-chevron-right'
              "
            />
            {{ data.item.name }}
          </div>
        </template>
        <!--end: Field "name"-->
        <!--begin: Field "primary" -->
        <template #cell(primary)="data">
          <div class="form-group mb-0">
            <label class="radio justify-content-center">
              <input
                v-model="analyzedData.primary"
                type="radio"
                name="radio-primary"
                :value="data.item.name"
                @input="primaryChanged(data.item.name)"
              />
              <span></span>
            </label>
          </div>
        </template>
        <!--end: Field "primary"-->
        <!--begin: Field "type" -->
        <template #cell(type)="data">
          <div class="form-group mb-0">
            <div class="input-group">
              <input
                v-model="data.item.type"
                type="text"
                class="form-control"
                disabled
                :placeholder="$t('dynamicModels.dynamicFieldPlaceholder')"
              />
            </div>
          </div>
        </template>
        <!--end: Field "type"-->
        <template #row-details="row">
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              <div class="">{{ $t("dynamicModels.dynamicFieldName") }}</div>
            </label>
            <div class="col-md-9 col-lg-8 col-xl-4">
              <div class="input-group">
                <input
                  v-model="row.item.name"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              <div class="">{{ $t("dynamicModels.dynamicFieldGlue") }}</div>
            </label>
            <div class="col-md-9 col-lg-8 col-xl-4">
              <div class="input-group">
                <input
                  v-model="row.item.glue"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              <div class="">{{ $t("dynamicModels.dynamicFieldValues") }}</div>
            </label>
            <div class="col-md-9 col-lg-8 col-xl-4">
              <v-select
                v-model="row.item.values"
                :items="analyzedData.fields"
                class="form-control"
                item-text="name"
                item-value="name"
                multiple
                hide-details
                :menu-props="{ offsetY: true }"
              />
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label">
              <div class="">{{ $t("dynamicModels.dynamicFieldPreview") }}</div>
            </label>
            <div class="col-md-9 col-lg-8 col-xl-4">
              <div class="input-group">
                <input
                  type="text"
                  :value="row.item.values.join(row.item.glue)"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <button
              class="btn btn-danger"
              @click="removeDynamicField(row.item.name)"
            >
              {{ $t("general.remove") }}
            </button>
          </div>
        </template>
      </b-table>
    </div>
    <div class="col-12">
      <h5 class="font-weight-bold">
        {{ $t("dynamicModels.addDynamicField") }}:
      </h5>
    </div>
    <div class="col-12 form-group">
      <input
        v-model="dynamicField"
        type="text"
        :placeholder="$t('dynamicModels.placeholderName')"
        class="col-6 col-lg-4 d-inline mr-3 form-control"
        :class="{ 'is-invalid': missingName }"
        @keydown.enter="addDynamicField"
      />
      <a
        href="#"
        class="btn btn-icon btn-circle btn-primary btn-sm"
        @click.prevent="addDynamicField"
      >
        <i class="fal fa-plus" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["analyzedData", "fields"],
  data() {
    return {
      dynamicField: "",
      showTable: false,
      missingName: false
    };
  },
  mounted() {
    if (!this.analyzedData.dynamic_fields)
      this.analyzedData.dynamic_fields = [];
    if (this.analyzedData.dynamic_fields.length) this.showTable = true;
  },
  methods: {
    addDynamicField() {
      if (!this.dynamicField.length) return (this.missingName = true);
      this.missingName = false;
      let newDynamic = {
        name: this.dynamicField,
        glue: "",
        values: [],
        index: false,
        nullable: false,
        type: "dynamic field",
        unique: false,
        unsigned: false,
        _showDetails: true
      };
      this.analyzedData.dynamic_fields.push(newDynamic);
      this.dynamicField = "";
      this.showTable = true;
      this.$refs["dynamicFieldsTable"].refresh();
    },
    removeDynamicField(name) {
      let toDelete = this.getIndexOf(name);
      if (toDelete >= 0) this.analyzedData.dynamic_fields.splice(toDelete, 1);
      this.$refs["dynamicFieldsTable"].refresh();
      if (!this.analyzedData.dynamic_fields.length) this.showTable = false;
    },
    primaryChanged(name) {
      let index = this.getIndexOf(name);
      let field = this.analyzedData.dynamic_fields[index];
      const primaryField = {
        name: name,
        glue: field.glue,
        values: field.values,
        index: true,
        nullable: false,
        type: "dynamic field",
        unique: true,
        unsigned: false
      };
      this.$set(this.analyzedData.dynamic_fields, index, primaryField);
      this.$refs["dynamicFieldsTable"].refresh();
    },
    getIndexOf(name) {
      return this.analyzedData.dynamic_fields.indexOf(
        this.analyzedData.dynamic_fields.find(item => item.name === name)
      );
    }
  }
};
</script>
