<template>
  <div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.encoding") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-4">
        <div class="input-group">
          <v-select
            v-model="config.encoding"
            class="form-control"
            :items="encodingOptions"
            item-text="item"
            item-value="item"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('dynamicModels.placeholderSelect')"
          />
          <div class="input-group-append">
            <span class="input-group-text border-0">
              <i class="fal fa-code icon-lg" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.delimiter") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-4">
        <div class="input-group">
          <input
            readonly
            type="text"
            :value="config.delimiter"
            class="form-control"
          />
          <div class="input-group-append">
            <span class="input-group-text border-0">
              <i class="fal fa-lock icon-lg" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mb-lg-5">{{ $t("dynamicModels.enclosure") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-4">
        <div class="input-group">
          <input
            readonly
            type="text"
            :value="config.enclosure"
            class="form-control"
          />
          <div class="input-group-append">
            <span class="input-group-text border-0">
              <i class="fal fa-lock icon-lg" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { encodingOptions } from "@/components/Tools/encodingOptions";

export default {
  props: ["config"],
  data() {
    return {
      encodingOptions: encodingOptions
    };
  }
};
</script>
