<template>
  <div>
    <div v-if="clientSelected">
      <Wizard v-if="$route.name === 'projectDynamicModelsDynamicModelCreate'" />
      <Details
        v-else-if="
          $route.name.includes('projectDynamicModelsDynamicModelDetails')
        "
      />
      <Table v-else />
    </div>

    <div v-if="!clientSelected" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("general.selectClientFirst") }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Projects/DynamicModels/DynamicModel/Table";
import Wizard from "@/components/Projects/DynamicModels/DynamicModel/Wizard";
import Details from "@/components/Projects/DynamicModels/DynamicModel/Details";

export default {
  components: {
    Table,
    Wizard,
    Details
  },
  computed: {
    clientSelected: function () {
      return this.$store.getters.selectedClient;
    }
  }
};
</script>
