import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}
ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}
class DynamicModels {
  get(id) {
    return ApiService.get(url, "dynamic_models/" + id);
  }
  getAll(params) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "dynamic_models" + options);
  }
  store(data) {
    return ApiService.post(url + "/dynamic_models", data);
  }
  update(id, data) {
    return ApiService.put(url + "/dynamic_models/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/dynamic_models/" + id);
  }
  analyze(data) {
    return ApiService.post(url + "/dynamic_models/analyze", data);
  }
  getFieldTypes() {
    return ApiService.get(url, "field_types");
  }
}
export default new DynamicModels();
