<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="row py-3">
              <div class="col-12">
                <div class="font-weight-bolder font-size-h5 text-dark-75">
                  <b-skeleton
                    v-if="!dynamicModel.id"
                    type="text"
                    height="1.5rem"
                  />
                  <span>{{ dynamicModel.classname }}</span>
                </div>
                <div class="text-muted">
                  <b-skeleton v-if="!dynamicModel.id" type="text" width="60%" />
                  <span>{{ dynamicModel.tablename }}</span>
                </div>
              </div>
            </div>
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mt-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route }"
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: isExactActive }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <Basic
          v-if="$route.name === 'projectDynamicModelsDynamicModelDetailsBasic'"
          @model-updated="loadModel"
        />
        <Table
          v-else-if="
            $route.name === 'projectDynamicModelsDynamicModelDetailsTable'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import DynamicModels from "@/components/Projects/DynamicModels/DynamicModel/dynamicModels";
import Basic from "@/components/Projects/DynamicModels/DynamicModel/Details/Basic";
import Table from "@/components/Projects/DynamicModels/DynamicModel/Details/Table";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Basic,
    Table
  },
  data() {
    return {
      dynamicModel: {},
      isBusy: false,
      tabIndex: 0,
      tabNavigation: [
        {
          route: "projectDynamicModelsDynamicModelDetailsBasic",
          label: "dynamicModels.detailsBasic",
          icon: "fal fa-sliders"
        },
        {
          route: "projectDynamicModelsDynamicModelDetailsTable",
          label: "dynamicModels.detailsTable",
          icon: "fal fa-list"
        }
      ]
    };
  },
  watch: {
    $route: function () {
      if (this.$route.name === "projectDynamicModelsDynamicModelDetails")
        this.$router.push({
          name: "projectDynamicModelsDynamicModelDetailsBasic"
        });
    }
  },
  mounted() {
    if (this.$route.params.id !== toString(this.dynamicModel.id))
      this.loadModel();
    if (this.$route.name === "projectDynamicModelsDynamicModelDetails")
      this.$router.push({
        name: "projectDynamicModelsDynamicModelDetailsBasic"
      });
  },
  methods: {
    loadModel() {
      this.isBusy = true;
      this.dynamicModel = {};
      let id = this.$route.params.id;
      DynamicModels.get(id)
        .then(response => {
          this.dynamicModel = response.data.data;
          this.setBreadCrumb();
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    setBreadCrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectDynamicModelsDynamicModel"),
          route: "/project/dynamicModels/dynamicModel"
        },
        {
          title: this.dynamicModel.classname,
          route: "/project/dynamicModels/dynamicModel/" + this.$route.params.id
        }
      ]);
    }
  }
};
</script>
