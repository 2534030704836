<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <div class="font-weight-bold h5 mb-0">{{ $t("general.caution") }}:</div>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        {{ $t("dynamicModels.noType") }}
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="">{{ $t("dynamicModels.modelType") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-4">
        <div class="input-group">
          <v-select
            v-model="type"
            class="form-control"
            :items="types"
            item-text="label"
            item-value="value"
            :menu-props="{ offsetY: true }"
            :placeholder="$t('dynamicModels.placeholderNoType')"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <button
          class="btn btn-primary"
          :disabled="typeof type !== 'number'"
          @click="$emit('save-type', type)"
        >
          {{ $t("dynamicModels.confirmType") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      types: [
        { value: 0, label: "CSV" },
        { value: 1, label: "XML" }
      ]
    };
  }
};
</script>
