<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("dynamicModels.detailsBasic") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'projectDynamicModelsDynamicModel' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="updateModel">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">
            {{ $t("dynamicModels.detailsBasic") }}:
          </h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.className") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="dynamicModel.classname"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.tableName") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <div class="input-group">
            <input
              type="text"
              :value="dynamicModel.tablename"
              class="form-control"
              readonly
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fal fa-lock icon-lg"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loadingFinished" class="separator separator-dashed my-5"></div>
      <div v-if="loadingFinished">
        <div v-if="typeof dynamicModel.config.type === 'number'">
          <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-6">
              <h5 class="font-weight-bold">
                {{ $t("dynamicModels.detailsBasicConfiguration") }}:
              </h5>
            </div>
          </div>
          <div v-if="dynamicModel.config.type === 0">
            <CSV :key="componentKey" :config="dynamicModel.config" />
          </div>
          <div v-else-if="dynamicModel.config.type === 1">
            <XML :key="componentKey" :config="dynamicModel.config" />
          </div>
        </div>
        <div v-else>
          <NoType @save-type="saveType" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicModels from "@/components/Projects/DynamicModels/DynamicModel/dynamicModels";
import CSV from "@/components/Projects/DynamicModels/DynamicModel/Details/Basic/CSV";
import XML from "@/components/Projects/DynamicModels/DynamicModel/Details/Basic/XML";

import NoType from "@/components/Projects/DynamicModels/DynamicModel/Details/NoType";

export default {
  components: {
    CSV,
    XML,
    NoType
  },
  data() {
    return {
      dynamicModel: {
        config: {},
        fields: []
      },
      loadingFinished: false,
      componentKey: 1,
      isBusy: false
    };
  },
  mounted() {
    this.loadModel();
  },
  methods: {
    loadModel() {
      this.isBusy = true;
      let id = this.$route.params.id;
      DynamicModels.get(id)
        .then(response => {
          this.dynamicModel = response.data.data;
          this.loadingFinished = true;
          this.componentKey++;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    updateModel() {
      this.isBusy = true;
      const id = this.dynamicModel.id;
      const data = this.dynamicModel;
      DynamicModels.update(id, data)
        .then(response => {
          this.dynamicModel = response.data.data;
          this.$emit("model-updated");
          this.$toast.fire({
            icon: "success",
            title: this.$t("dynamicModels.modelUpdated")
          });

          this.componentKey++;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    saveType(type) {
      this.dynamicModel.config.type = type;
      this.updateModel();
    }
  }
};
</script>
