<template>
  <div class="client-details">
    <dynamicmodels
      v-if="$route.name.includes('projectDynamicModelsDynamicModel')"
    />
    <wip v-else />
  </div>
</template>

<script>
import dynamicmodels from "@/components/Projects/DynamicModels/DynamicModel/Index";
import wip from "@/components/SYS/WIP";

export default {
  components: {
    dynamicmodels,
    wip
  }
};
</script>
