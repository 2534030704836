<template>
  <div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="">{{ $t("dynamicModels.selector") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-4">
        <div class="d-flex align-items-center">
          <div class="input-group flex-grow-1 mr-1">
            <input
              readonly
              type="text"
              :value="config.selector"
              class="form-control"
            />
            <div class="input-group-append">
              <span class="input-group-text border-0">
                <i class="fal fa-lock icon-lg" />
              </span>
            </div>
          </div>
          <Tooltip :text="$t('dynamicModels.selectorHelp')" />
        </div>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="">{{ $t("dynamicModels.entryQuantity") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-4">
        <div class="d-flex align-items-center">
          <div class="input-group mr-1">
            <input
              v-model="config.entryQuantity"
              type="number"
              min="1"
              class="form-control"
            />
          </div>
          <Tooltip :text="$t('dynamicModels.entryQuantityHelp')" />
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xl-3 col-lg-3 col-form-label">
        <div class="mt-3">{{ $t("dynamicModels.forceArrayOn") }}</div>
      </label>
      <div class="col-md-9 col-lg-8 col-xl-4">
        <div
          v-for="(item, i) in forceArrayOn"
          :key="i"
          class="d-flex align-items-center mb-1"
        >
          <div class="input-group mr-1">
            <input
              v-model="forceArrayOn[i]"
              type="text"
              class="form-control"
              @keydown.tab="onTab(i, $event)"
            />
          </div>
          <i
            class="fal fa-circle-minus icon-lg text-danger cursor-pointer"
            @click="removeField(i)"
          />
        </div>
        <div class="d-flex justify-space-between align-items-center">
          <button class="btn btn-primary" @click="addField">
            <i class="fal fa-plus" />{{ $t("dynamicModels.forceArrayOnAdd") }}
          </button>
          <Tooltip :text="$t('dynamicModels.forceArrayOnHelp')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/Tools/Tooltip";
export default {
  components: {
    Tooltip
  },
  props: ["config"],
  data() {
    return {
      forceArrayOn: JSON.parse(this.config.forceArrayOn ?? "[]")
    };
  },
  watch: {
    forceArrayOn: {
      deep: true,
      handler: function () {
        let filteredArray = this.forceArrayOn.filter(entry => entry.length);
        this.config.forceArrayOn = JSON.stringify(filteredArray);
      }
    }
  },
  methods: {
    addField() {
      this.forceArrayOn.push("");
    },
    removeField(key) {
      this.forceArrayOn.splice(key, 1);
    },
    onTab(key, e) {
      if (key === this.forceArrayOn.length - 1 && !e.shiftKey) this.addField();
    }
  }
};
</script>
