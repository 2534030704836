<template>
  <v-tooltip v-bind="attributes">
    <template #activator="{ on, attrs }">
      <i class="fal fa-circle-question" dark v-bind="attrs" v-on="on" />
    </template>
    <span v-html="output"></span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["text", "orientation", "glue"],
  data() {
    return {};
  },
  computed: {
    attributes: function () {
      return {
        [this.orientation ?? "bottom"]: true
      };
    },
    output: function () {
      let content = this.text;
      if (Array.isArray(content)) {
        let glue = this.glue ?? " ";
        return content.join(glue);
      }
      return content;
    }
  }
};
</script>

<style>
.v-tooltip__content {
  max-width: 300px;
}
</style>
