<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("dynamicModels.detailsTable") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary mr-2"
          @click="$router.push({ name: 'projectDynamicModelsDynamicModel' })"
        >
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn-primary" @click="updateModel">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">
            {{ $t("dynamicModels.detailsTable") }}:
          </h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.primaryKey") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <div class="input-group">
            <input
              type="text"
              :value="dynamicModel.primary"
              class="form-control"
              readonly
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fal fa-lock icon-lg"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <b-table
            responsive
            :items="dynamicModel.fields"
            :fields="columns"
            class="dataTable table table-head-custom"
            table-class="b-table-fixed"
            bordered
            :show-empty="true"
            :empty-text="$t('table.noRecords')"
          >
            <!--begin: Actions-->
            <template #cell(actions)="data">
              <div class="d-flex flex-nowrap">
                <button
                  class="btn btn-secondary btn-icon btn-sm mr-1"
                  @click="duplicateRow(data.index)"
                >
                  <i class="fal fa-copy" />
                </button>
                <button
                  v-if="data.item.name !== dynamicModel.primary"
                  class="btn btn-secondary btn-icon btn-sm"
                  @click="deleteRow(data.item)"
                >
                  <i class="fal fa-trash" />
                </button>
              </div>
            </template>
            <!--end: Actions-->

            <!--begin: Checkbox for every field -->
            <template #cell()="data">
              <span class="switch switch-sm justify-content-center">
                <label>
                  <input
                    v-model="data.item[data.field.key]"
                    type="checkbox"
                    :disabled="
                      (data.field.key === 'unsigned' &&
                        !data.item['type'].toLowerCase().includes('int')) ||
                      data.field.key === 'isDynamic' ||
                      data.item.name === dynamicModel.primary
                    "
                  />
                  <span></span>
                </label>
              </span>
            </template>
            <!--end: Checkbox for every field-->
            <!--begin: Field "name" -->
            <template #cell(name)="data">
              <div
                v-if="data.item.isDynamic"
                class="cursor-pointer d-flex align-items-center"
                @click="data.toggleDetails"
              >
                <i
                  class="fal mr-2"
                  :class="
                    data.item._showDetails
                      ? 'fa-chevron-down'
                      : 'fa-chevron-right'
                  "
                />
                <div class="form-group mb-0">
                  <b-form-input
                    v-model="data.item.name"
                    type="text"
                    class="form-control"
                    :readonly="data.item.name === dynamicModel.primary"
                  />
                </div>
              </div>
              <div v-else>
                <div class="form-group mb-0">
                  <b-form-input
                    v-model="data.item.name"
                    type="text"
                    class="form-control"
                    :disabled="data.item.name === dynamicModel.primary"
                  />
                </div>
              </div>
            </template>
            <!--end: Field "name"-->
            <!--begin: Field "type" -->
            <template #cell(type)="data">
              <div class="form-group mb-0">
                <v-select
                  v-if="!data.item.isDynamic"
                  v-model="data.item.type"
                  class="form-control"
                  :items="fieldTypes"
                  item-text="name"
                  item-value="name"
                  :menu-props="{ offsetY: true }"
                  :placeholder="$t('dynamicModels.placeholderSelect')"
                />
                <div v-else class="input-group">
                  <input
                    v-model="data.item.type"
                    type="text"
                    class="form-control"
                    disabled
                    :placeholder="$t('dynamicModels.dynamicFieldPlaceholder')"
                  />
                </div>
              </div>
            </template>
            <!--end: Field "type"-->
            <template #row-details="row">
              <div v-if="row.item.isDynamic">
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    <div class="">
                      {{ $t("dynamicModels.dynamicFieldName") }}
                    </div>
                  </label>
                  <div class="col-md-9 col-lg-8 col-xl-4">
                    <div class="input-group">
                      <input
                        v-model="row.item.name"
                        readonly
                        type="text"
                        class="form-control"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fal fa-lock icon-lg"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    <div class="">
                      {{ $t("dynamicModels.dynamicFieldGlue") }}
                    </div>
                  </label>
                  <div class="col-md-9 col-lg-8 col-xl-4">
                    <div class="input-group">
                      <input
                        v-model="row.item.glue"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    <div class="">
                      {{ $t("dynamicModels.dynamicFieldValues") }}
                    </div>
                  </label>
                  <div class="col-md-9 col-lg-8 col-xl-4">
                    <v-select
                      v-model="row.item.values"
                      :items="dynamicModel.fields"
                      item-text="name"
                      item-value="name"
                      multiple
                      outlined
                      hide-details
                      dense
                    />
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    <div class="">
                      {{ $t("dynamicModels.dynamicFieldPreview") }}
                    </div>
                  </label>
                  <div class="col-md-9 col-lg-8 col-xl-4">
                    <div class="input-group">
                      <input
                        type="text"
                        :value="row.item.values.join(row.item.glue)"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
          <button class="btn btn-primary" @click="addField">
            <i class="fal fa-plus" />
            {{ $t("dataStructures.addField") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicModels from "@/components/Projects/DynamicModels/DynamicModel/dynamicModels";

export default {
  data() {
    return {
      dynamicModel: {
        config: {},
        fields: []
      },
      fieldTypes: [],
      columns: [
        { key: "name", label: "name", thStyle: "width: 150px" },
        { key: "index", label: "index", thStyle: "width: 70px" },
        { key: "nullable", label: "nullable", thStyle: "width: 90px" },
        { key: "type", label: "type", thStyle: "width: 120px" },
        { key: "unique", label: "unique", thStyle: "width: 75px" },
        { key: "unsigned", label: "unsigned", thStyle: "width: 95px" },
        { key: "isDynamic", label: "dynamic field", thStyle: "width: 95px" },
        {
          key: "actions",
          label: this.$t("table.actions"),
          thStyle: "width: 90px"
        }
      ],
      isBusy: false
    };
  },
  mounted() {
    this.loadModel();
    this.getFieldTypes();
  },
  methods: {
    addField() {
      this.dynamicModel.fields.push({
        name: "",
        index: "",
        nullable: "",
        type: "",
        unique: "",
        unsigned: "",
        isDynamic: ""
      });
    },
    duplicateRow(index) {
      let copy = Object.assign({}, this.dynamicModel.fields[index]);
      if (!copy.name.includes("- COPY")) {
        copy.name += " - COPY";
      }
      this.dynamicModel.fields.push(copy);
    },
    deleteRow(item) {
      let index = this.dynamicModel.fields.findIndex(field => field === item);
      this.dynamicModel.fields.splice(index, 1);
    },
    loadModel() {
      this.isBusy = true;
      let id = this.$route.params.id;
      DynamicModels.get(id)
        .then(response => {
          this.dynamicModel = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getFieldTypes() {
      DynamicModels.getFieldTypes()
        .then(response => {
          this.fieldTypes = response.data;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
        });
    },
    updateModel() {
      this.isBusy = true;
      const id = this.dynamicModel.id;
      const data = this.dynamicModel;
      DynamicModels.update(id, data)
        .then(response => {
          this.dynamicModel = response.data.data;
          this.$emit("model-updated");
          this.$toast.fire({
            icon: "success",
            title: this.$t("dynamicModels.modelUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-control {
  &.v-select {
    &.v-input {
      .v-input__slot {
        padding-top: 8px;
      }
    }
  }
}
</style>
