<template>
  <div data-wizard-type="step-content">
    <div v-if="Object.keys(analyzedData).length">
      <div class="row">
        <div class="col-12">
          <h5 class="font-weight-bold">
            {{ $t("dynamicModels.stepTableConfig") }}:
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-table
            v-if="Array.isArray(analyzedData.fields)"
            responsive
            :items="analyzedData.fields"
            :fields="fields"
            class="dataTable table table-head-custom"
            table-class="b-table-fixed"
            bordered
            :show-empty="true"
            :empty-text="$t('table.noRecords')"
          >
            <!--begin: Checkbox for every field -->
            <template #cell()="data">
              <span class="switch switch-sm justify-content-center">
                <label>
                  <input
                    v-model="data.item[data.field.key]"
                    type="checkbox"
                    :disabled="
                      (data.field.key === 'unsigned' &&
                        !data.item['type'].toLowerCase().includes('int')) ||
                      analyzedData.primary === data.item.name
                    "
                  />
                  <span></span>
                </label>
              </span>
            </template>
            <!--end: Checkbox for every field-->
            <!--begin: Field "name" -->
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <!--end: Field "name"-->
            <!--begin: Field "primary" -->
            <template #cell(primary)="data">
              <div class="form-group mb-0">
                <label class="radio justify-content-center">
                  <input
                    v-model="analyzedData.primary"
                    type="radio"
                    name="radio-primary"
                    :value="data.item.name"
                    @input="primaryChanged(data.item.name)"
                  />
                  <span></span>
                </label>
              </div>
            </template>
            <!--end: Field "primary"-->
            <!--begin: Field "type" -->
            <template #cell(type)="data">
              <div class="form-group mb-0">
                <v-select
                  v-model="data.item.type"
                  class="form-control"
                  :items="fieldTypes"
                  item-text="name"
                  item-value="name"
                  :menu-props="{ offsetY: true }"
                  :placeholder="$t('dynamicModels.placeholderSelect')"
                />
              </div>
            </template>
            <!--end: Field "type"-->
          </b-table>
        </div>
      </div>

      <span v-if="analyzedData.primary === undefined" class="text-danger">{{
        $t("dynamicModels.selectPrimaryKey")
      }}</span>

      <DynamicFields
        v-if="showDynamicFields"
        :analyzed-data="analyzedData"
        :fields="fields"
      />
    </div>
  </div>
</template>

<script>
import DynamicModels from "@/components/Projects/DynamicModels/DynamicModel/dynamicModels";
import DynamicFields from "@/components/Projects/DynamicModels/DynamicModel/DynamicFields";

export default {
  components: {
    DynamicFields
  },
  props: ["analyzedData"],
  data() {
    return {
      fieldTypes: [],
      dynamicField: "",
      isBusy: false
    };
  },
  computed: {
    fields() {
      return [
        { key: "name", label: "name", thStyle: "width: 150px" },
        { key: "primary", label: "primary", thStyle: "width: 85px" },
        { key: "index", label: "index", thStyle: "width: 70px" },
        { key: "nullable", label: "nullable", thStyle: "width: 90px" },
        { key: "type", label: "type", thStyle: "width: 120px" },
        { key: "unique", label: "unique", thStyle: "width: 75px" },
        { key: "unsigned", label: "unsigned", thStyle: "width: 95px" }
      ];
    },
    showDynamicFields() {
      return this.analyzedData.type === 0;
    }
  },
  mounted() {
    if (Object.keys(this.analyzedData).length) {
      this.getFieldTypes();
      this.primaryChanged(this.analyzedData.primary);
    }
  },
  methods: {
    getFieldTypes() {
      this.isBusy = true;
      DynamicModels.getFieldTypes()
        .then(response => {
          this.fieldTypes = response.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    primaryChanged(name) {
      let index = this.analyzedData.fields.indexOf(
        this.analyzedData.fields.find(item => item.name === name)
      );
      if (index < 0) return;
      let field = this.analyzedData.fields[index];
      const primaryField = {
        name: name,
        index: true,
        unique: true,
        nullable: false,
        type: field.type,
        unsigned: false
      };
      this.$set(this.analyzedData.fields, index, primaryField);
      this.$emit("primaryChanged");
    }
  }
};
</script>
