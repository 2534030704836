<template>
  <div data-wizard-type="step-content">
    <div v-if="Object.keys(analyzedData).length">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">
            {{ $t("dynamicModels.selectedConfiguration") }}:
          </h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.file") }}
        </label>
        <div class="col-md-9 col-lg-8 col-xl-4">
          <div class="input-group">
            <input
              v-model="analyzedData.filename"
              type="text"
              class="form-control"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.fileType") }}
        </label>
        <div class="col-md-9 col-lg-8 col-xl-4">
          <div class="input-group">
            <input
              type="text"
              :value="types[analyzedData.type]"
              class="form-control"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.primaryKey") }}
        </label>
        <div class="col-md-9 col-lg-8 col-xl-4">
          <div class="input-group">
            <input
              v-model="analyzedData.primary"
              type="text"
              class="form-control"
              readonly
            />
          </div>
        </div>
      </div>
      <div
        v-if="analyzedData.config.encoding"
        class="form-group row align-items-center"
      >
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("dynamicModels.encoding") }}
        </label>
        <div class="col-md-9 col-lg-8 col-xl-4">
          <div class="input-group">
            <input
              v-model="analyzedData.config.encoding"
              type="text"
              class="form-control"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["analyzedData"],
  data() {
    return {
      types: ["CSV", "XML", "JSON"]
    };
  }
};
</script>
