<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("dynamicModels.dynamicModels") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary btn-hover"
          @click.left="
            $router.push({ name: 'projectDynamicModelsDynamicModelCreate' })
          "
          @click.middle="
            $router.push({ name: 'projectDynamicModelsDynamicModelCreate' })
          "
        >
          <i class="fal fa-plus"></i>
          {{ $t("dynamicModels.createModel") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <div class="row justify-content-between align-items-center mb-3">
        <!--begin::Pagination-->
        <div class="col-auto d-flex align-items-center">
          <b-form-select
            v-model="pageSize"
            :options="pageSizes"
            class="mr-3 shadow-none"
            value-field="item"
            text-field="name"
            size="sm"
            disabled-field="notEnabled"
            @change="handlePageSizeChange"
          ></b-form-select>
          <span class="text-muted text-no-wrap">
            {{ $t("general.recordsPerPage") }}
          </span>
        </div>
        <div class="col-auto">
          <b-input
            v-model="searchTitle"
            :placeholder="$t('search.searchTerm')"
          />
        </div>
        <!--end:: Pagination-->
      </div>
      <div class="dataTables_wrapper">
        <b-table
          responsive
          :items="models"
          :fields="fields"
          class="dataTable table table-head-custom"
          :filter="searchTitle"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="editModel(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteModel(data.item)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
        </b-table>
      </div>
      <div class="row justify-content-between align-items-center mt-3">
        <div class="col-auto">
          <span v-if="Object.keys(meta).length" class="text-muted text-no-wrap">
            {{
              $t("general.records", {
                from: meta.from,
                to: meta.to,
                total: meta.total
              })
            }}
          </span>
        </div>
        <div class="col-auto d-flex flex-wrap">
          <b-pagination
            v-model="page"
            :total-rows="totalRecords"
            :per-page="pageSize"
            size="md"
            class="pl-0 mb-0"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicModels from "@/components/Projects/DynamicModels/DynamicModel/dynamicModels";
import { formatDate } from "@/components/Tools/modifiers";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      searchTitle: "",
      models: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "classname",
          label: this.$t("table.className"),
          sortable: true
        },
        {
          key: "tablename",
          label: this.$t("table.tableName"),
          sortable: true
        },
        {
          key: "primary",
          label: this.$t("table.primary"),
          sortable: true
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      // Meta info
      meta: {},
      page: 1,
      totalPages: 10,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      totalRecords: 0,
      isBusy: false
    };
  },
  computed: {
    ...mapGetters(["selectedClient"])
  },
  watch: {
    selectedClient: function () {
      this.loadModels();
    }
  },
  mounted() {
    this.loadModels();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.projectDynamicModelsDynamicModel"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/project/dynamicModels/dynamicModel"
      }
    ]);
  },
  methods: {
    loadModels() {
      if (this.selectedClient === null) {
        this.models = [];
        return false;
      }

      this.isBusy = true;
      const params = this.getRequestParams(this.page, this.pageSize);
      DynamicModels.getAll(params)
        .then(response => {
          this.models = response.data.data;
          this.setMeta(response.data.meta);
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) params["page"] = page;
      if (pageSize) params["size"] = pageSize;
      return params;
    },
    setMeta(meta) {
      this.meta = meta;
      this.totalPages = meta.last_page;
      this.totalRecords = meta.total;
    },
    handlePageChange(value) {
      this.page = value;
      this.loadModels();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.loadModels();
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    editModel(id) {
      this.$router.push({
        name: "projectDynamicModelsDynamicModelDetails",
        params: { id: id }
      });
    },
    deleteModel(model) {
      let id = model.id;
      let name = model.classname;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("dynamicModels.deleteModelTitle"),
          text: this.$t("dynamicModels.deleteModelText", { name: name }),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "#F64E60"
        })
        .then(result => {
          if (!result.isConfirmed) return;
          this.deleteModelAction(id);
        });
    },
    deleteModelAction(id) {
      this.isBusy = true;
      DynamicModels.delete(id)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("dynamicModels.modelDeletedSuccess")
          });
          this.loadModels();
        })
        .catch(error => {
          this.$toast.fire({
            title: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
